/*
This is where you write custom SASS
*/

  @media (min-width: 1200px) {
    .container {
        max-width: 1250px;
    }
  }
  .bg-nav {
    background-color: transparent;
    z-index: 2;
  }
  .bg-main {
    background-color: #fffcf9;
  }
  header {
    .title {
      .weight {
        font-weight: 600;
        letter-spacing: 0.01em;
        color: #3B522C;
      }
    }
    .topInfo {
      font-size: 18px;
      letter-spacing: -0.02em;
      padding: 12px 20px 0 0;

      span {
        color: rgba(#728466, 0.25);
      }
    }
    .menu-toggle {
      color: #fff;
      background-color: #728466;
      padding: 12px 15px;
      font-size: 21px;
      text-align: right;
    }
  }
  .modal.show, .modal.show + .modal-backdrop {
    overflow: hidden;
  }
  .modal-backdrop, #pageOverlay {
    background: rgba($black, 0.85);
    position: fixed;
    z-index: 1048;
    top: -40px;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
  }
  .modal-backdrop {
    background: none !important;
  }
  .modal-open {
    overflow: auto;
  }
  .modal-backdrop, #pageOverlay {
    background: rgba($black, 0.85);
    position: fixed;
    z-index: 1048;
    top: -40px;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
  }
  .modal-backdrop {
    background: none !important;
  }
  body.mobile-nav-show #fullPageWrapper #pageOverlay, body.modal-open #fullPageWrapper #pageOverlay, body.modal-open .modal-backdrop {
    opacity: 1;
    visibility: visible;
  }
  .modal {
    transition: opacity 0.4s ease, visibility 0.4s ease;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    .modal-dialog {
      transition: 0.4s ease;
      opacity: 0;
      transform: translate3d(0, -2.5rem, 0) !important;
    }
  }
  .modal-open {
    .modal {
      opacity: 1;
      visibility: visible;
      .modal-dialog {
        opacity: 1;
        transform: translate3d(0, 0, 0) !important;
      }
    }
  }
  body.mobile-nav-show {
    nav.navbar {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }
  }
  nav.navbar {
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    z-index: 1055;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    transition: 0.4s ease;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(25%, 0, 0);
    will-change: transform;
    > * {
      width: 80vw;
      max-width: 400px;
    }
    #navbarSupportedContent {
      display: block !important;
      height: auto !important;
    }
    ul {
      width: auto !important;
      margin: 0 !important;
      border: none !important;
      background-color: transparent !important;
      li {
        a {
          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 5%;
            left: 10%;
            right: 10%;
            transition: 0.3s ease;
            border-radius: $border-radius;
            background-color: rgba(map-get($theme-colors, dark), 0.1) !important;
            opacity: 0;
          }
          body[user-agent*="Mobile"] &:hover:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
          &:not(.has-submenu) {
            padding-right: $spacer * 1.5 !important;
          }
        }
        a.highlighted {
          background-color: transparent !important;
        }
      }
      ul {
        margin-left: $spacer !important;
        margin-bottom: 1rem;
      }
    }
  }
  .sub-arrow {
    border-radius: $border-radius !important;
    border: none !important;
    transition: transform 0.2s ease;
    // margin-right: 0 !important;
    // margin-left: 0 !important;
    &:before {
      content: '\f105' !important;
      font-family: 'Font Awesome 5 Pro';
      font-weight: lighter;
      margin-right: 0 !important;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      opacity: 0;
    }
    svg {
      margin-right: 0 !important;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  [aria-expanded="true"] {
    & > .sub-arrow {
      transform: rotate(90deg);
    }
  }
  .navbar {
    .dropdown-item:focus {
      background-color: transparent !important;
    }
    .dropdown .dropdown-menu {
      margin: 1rem 0 0 -.5rem !important;
      background-color: #728466;
    }
    .dropdown .dropdown-menu::before {
      background-color: #728466;
    }
  }
  nav a[aria-expanded="true"] > span.sub-arrow {
    top: 25px !important;
  }
  section.slider {
    background-color: #fffcf9;

    .mod {
      height: 1130px;

      p {
        top: -100px;
        position: relative;
      }
    }
  }
  section.appointment {
    margin: -310px 0 0;
    position: relative;
    z-index: 1;

    .wrap {
      background: url('/prositesStyles/StyleLibrary/6463/images/addrsBg.jpg');
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 67px 60px;
      border-radius: 5px;
      box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.06);
      border: 1px solid rgba($white, 0.18);

      .h1 {
        letter-spacing: 0.03em;
      }
      .btn {
        font-weight: 400;
        letter-spacing: -0.02em;
        min-width: 240px;
      }
      .mod {
        background-color: #4D5547;
        border-color: #4D5547;

        &:hover {
          background-color: #41473c;
          border-color: #41473c;
        }

      }
    }
  }
  section.ctas {
    position: relative;
    z-index: 2;

    .brdr {
      border-radius: 5px;
      border: 1px solid rgba($black, 0.07);
      box-shadow: 0px 10px 60px rgba($color: #000000, $alpha: 0.06em);
    }
    .mod {
      padding: 295px 0 0;

      h4 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.03em;
        color: #3B522C;
      }
    }
    p {
      color: #9CA894;
    }
    a:hover {
      p {
        color: #3B522C !important;
        transition: 0.3s ease !important;
        -webkit-transition: 0.3s ease !important;
        -moz-transition: 0.3s ease !important;
      }
    }
  }
  section.main {
    font-weight: 600;

    h1 {
      margin-bottom: 25px;
    }
  }
  section.services {
    background: url('/prositesStyles/StyleLibrary/6463/images/serviceBg.jpg');
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 1120px;
    padding: 16% 0 0 0;

    .brdr {
      border-radius: 5px;
      border: 1px solid rgba($black, 0.07);
    }
    sup {
        top: -0.9em;
        font-size: 12px;
    }
    .mod {
      padding: 205px 0 0;
    }
    a:hover {
      h3 {
        color: #8d9787 !important;
        transition: 0.3s ease !important;
        -webkit-transition: 0.3s ease !important;
        -moz-transition: 0.3s ease !important;
      }
    }
  }
  section.contact-form {
    background-color: #323A2D;

    .testimonials {
      blockquote {
        color: #fff;
      }
      .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
        display: none !important;
      }
      .owl-carousel .owl-dots {
          left: 5%;
      }
      cite {
          font-size: 18px;
          letter-spacing: 0.04em;
          color: rgba(#ffffff, 0.66);

          :nth-child(2) {
              display: none !important;
          }
          :nth-child(3) {
              display: none !important;
          }
      }
    }
    .border-bottom {
        border-bottom: 1px solid rgba(#ffffff, 0.16) !important;
        margin: 30px 0;
    }
    .form {
      margin-left: 45px;

      .form-control {
        border: 1px solid rgba(#261918, 0.1) !important;
      }

      .form-group, .form-row {
          margin-bottom: 10px;
          margin-top: 10px !important;
      }
      ::placeholder {
          font-size: 17px;
          font-weight: 500;
          color: rgba(#000000, 0.5);
          opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder {
        font-size: 17px;
        font-weight: 500;
        color: rgba(#000000, 0.5);
      }
      
      ::-ms-input-placeholder {
        font-size: 17px;
        font-weight: 500;
        color: rgba(#000000, 0.5);
      }
      .btn {
          padding: 20px 40px;
          min-width: 200px;
      }
    }
  }
  section.map {
    background-color: #32392d;
    z-index: 1;

    .mod {
      bottom: -50px;

      .border-radius {
        border-radius: 5px;
      }
    }
  }
  footer.foot {
    background-color: #1C1F1A;
    padding: 125px 0 75px;

    br {
      display: none !important;
    }
    .btm {
      .mod {
        font-size: 14px;
        color: rgba(#ffffff, 0.4);
        font-weight: 600;

        a {
          color: rgba(#ffffff, 0.4);

          &:hover {
            color: rgba(#ffffff, 1);
          }
        }
      }
    }
  }
  .page-type-3 {
    .contact-form {
        .form {
            .card-body {
                background-color: #32392D;
                padding: 70px;
      
              .h3 {
                  font-size: 30px;
                  color: #fff;
                  font-weight: 500;
                  margin-bottom: 25px;
              }
              .mb-3 {
                color: #fff !important;
                margin-bottom: 20px !important;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0.04em;
              }
              label {
                display: none;
              }
            }
          }
          .form-group {
            margin-bottom: 7px !important;
            margin-top: 0 !important;
          }
          .form-control {
            border: none;
            border-radius: 0;
          }
          textarea {
            margin: 0 0 20px 0;
            min-height: 164px;
          }
          ::placeholder {
            font-size: 17px;
            font-weight: 500;
            color: rgba(#000000, 0.5);
          }
          :-ms-input-placeholder {
            font-size: 17px;
            font-weight: 500;
            color: rgba(#000000, 0.5);
          }
          ::-ms-input-placeholder {
            font-size: 17px;
            font-weight: 500;
            color: rgba(#000000, 0.5);
          }
    }
}
  [class*="btn"]:not(.no-fx), .hover-fx {
    transition: 0.3s ease !important;
  }
  [class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx:not([class*="owl"]):hover {
      transform: translate3d(0, -2px, 0);
      box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
  }


  @media (max-width: 1300px) {
    header .topInfo {
        font-size: 15px;
    }
  }
  @media (max-width: 1200px) {
    section.services {
        padding: 19% 0 45px;
    }
    section.contact-form .testimonials .owl-carousel .owl-dots {
        left: 7%;
    }
    section.services {
        min-height: 900px;
    }
    header .topInfo {
        padding: 0 20px 0 0;
        line-height: 15px;
    }
  }
  @media (max-width: 992px) {
    header.position-absolute {
      position: relative !important;
    }
    section.contact-form .form {
        margin: 75px 0 0 0;
        text-align: center;
    }
    .bg-nav .mod {
        margin: 0;
        bottom: 37px;
        position: relative;
    }
    section.contact-form .testimonials .owl-carousel .owl-dots {
        left: 50%;
    }
  }
  @media (max-width: 768px) {
    section.ctas .mod {
        padding: 65% 0 0;
    }
    section.services .tb {
      color: #4D5547 !important;
    }
    section.appointment .wrap .btn {
        min-width: 100%;
    }
    section.slider .mod p {
        top: -95px;
    }
    section.services .mod {
        padding: 55% 0 0;
    }
    footer.foot .btm .mod {
        text-align: center;
        margin-bottom: 20px;
    }
    section.services {
      padding: 45px 0;
    }
    .bg-nav .mod {
        margin: -37px 0 30px;
        bottom: 0;
    }
  }
  @media (max-width: 576px) {
    .bg-nav .mod {
        margin: -60px 0 30px;
    }
    section.slider .mod .display-1 {
      font-size: 40px;
    }
    section.appointment .wrap {
        padding: 40px 15px;
    }
    section.slider .mod p {
        top: -100px;
    }
    .bg-nav .m-icons {
      position: relative;
      top: -7px;
    }
    section.slider .mod {
        height: 675px;
    }
  }
  @media (max-width: 480px) {}
  @media (max-width: 320px) {
    section.slider .mod p {
        top: -120px;
    }
    section.slider .mod .display-1 {
        font-size: 35px;
    }
    section.slider .mod {
        height: 600px;
    }
    section.appointment .wrap h4 {
      font-size: 18px;
    }
    .bg-nav .mod {
        margin: -65px 0 30px;
    }
    .bg-nav .m-icons {
        top: -15px;
    }
  }